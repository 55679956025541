import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import './Navbar.scss';
import {Link, useNavigate} from "react-router-dom";
import {Close} from "@mui/icons-material";

const pages = ['Works', 'Design', 'References', 'Press', 'About'];

export const Navbar: React.FC = () => {
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <div className="navbar-container">
            <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>


                        <img
                            src="https://static.strezoski.com/00.LOGO/navbar_logo.png"
                            className="logo" onClick={() => navigate("")}/>

                        <Box sx={{flexGrow: 0, marginLeft: 'auto', display: {xs: 'none', md: 'flex'}}}>
                            {pages.map((page) => (
                                <Button
                                    className={`navbar-button ${page.toLowerCase() === window.location.pathname.substring(1) ? 'active' : ''}`}
                                    key={page}
                                    onClick={handleCloseNavMenu}
                                    sx={{my: 2, color: 'white', display: 'block'}}
                                >
                                    <Link to={page.toLowerCase()}>{page}</Link>
                                </Button>
                            ))}
                        </Box>
                        <Box className="here" sx={{flexGrow: 0, marginLeft: 'auto', display: {xs: 'flex', md: 'none'}}}>
                            <Menu
                                id="menu-appbar"
                                keepMounted
                                className="mobile-menu"
                                open={!!anchorElNav}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: {xs: 'block', md: 'none'},
                                }}
                            >
                                <div className="menu-container">
                                    {pages.map((page) => (
                                        <Typography textAlign="center" onClick={handleCloseNavMenu}
                                                    className={`navbar-button ${page.toLowerCase() === window.location.pathname.substring(1) ? 'active' : ''}`}
                                        ><Link
                                            to={page.toLowerCase()}>{page}</Link></Typography>
                                    ))}
                                </div>
                            </Menu>
                            {anchorElNav ? (
                                    <IconButton
                                        edge="end"
                                        size="large"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleCloseNavMenu}
                                        color="inherit"
                                    >
                                        <Close/>
                                    </IconButton>
                            ) :
                                (
                                    <IconButton
                                edge="end"
                                size="large"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon/>
                            </IconButton>
                                )}

                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </div>
    );
}