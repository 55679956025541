import * as React from 'react';
import {useEffect, useState} from 'react';
import './WorksList.scss';
import {useNavigate} from "react-router-dom";
import {Footer} from "../../components/Footer/Footer";

const filterCategories = ["Selected Works", "Medical", "Industrial", "Housing", "Interior Design"];

export const WorksList: React.FC = () => {
    const [activeFilterIndex, setActiveFilterIndex] = useState(-1);

    const navigate = useNavigate();
    const [works, setWorks] = useState<any>({});
    const [filteredWorks, setFilteredWorks] = useState<any>([]);

    const fetchData = () => {
        fetch('/data/works.json'
            , {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        ).then((response) => {
            return response.json();
        }).then(res => {
            setWorks(res)
            setFilteredWorks(Object.keys(res))
        })
    }

    useEffect(() => {
        fetchData()
    }, [])

    const handleFilterClick = (event: any, index: number, category?: string) => {
        event.preventDefault();
        setActiveFilterIndex(index);

        if (index < 0) {
            setFilteredWorks(Object.keys(works));
            setActiveFilterIndex(-1);
        } else {
            setFilteredWorks(Object.keys(works).filter((key: string) => {
                return works[key].category.includes(category)
            }))
            setActiveFilterIndex(index);
        }
    }

    return (
        <div className="container">
            <div className="filter-container">
                {
                    filterCategories.map((category, index) => (
                        <a className={['filter', activeFilterIndex === index ? 'active' : ''].join(' ')} href="#"
                           onClick={(e) => handleFilterClick(e, index, category)}>{category}</a>
                    ))
                }
                <a className={['filter', activeFilterIndex === -1 ? 'active' : ''].join(' ')} href="#"
                   onClick={(e) => handleFilterClick(e, -1)}>All Categories</a>
            </div>
            <div className="works-container">
                {
                    !!filteredWorks.length && filteredWorks.map((key: string) => {
                        const work = works[key];
                        return (
                            <div className="work-container" onClick={() => navigate(key)}>
                                <img src={work.list_image_path} className="main-image"/>
                                <div className="work-name">{work.name}</div>
                            </div>
                        )
                    })
                }
            </div>
            <Footer/>
        </div>
    );
}