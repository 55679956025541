import * as React from 'react';
import {useEffect, useState} from 'react';
import './WorksItem.scss';
import {useNavigate, useParams} from "react-router-dom";
import {CarouselContainer} from "../../../components/Carousel/Carousel";
import {Footer} from "../../../components/Footer/Footer";
import Markdown from "react-markdown";

const videoExtensions = ['webm', 'mov', 'mp4'];

export const WorksItem: React.FC = () => {
    const [works, setWorks] = useState<any>({});
    const {id} = useParams();
    const navigate = useNavigate();

    const fetchData = () => {

        fetch('/data/works.json'
            , {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        ).then((response) => {
            return response.json();
        }).then(res => {
            setWorks(res)
            if (!res[id!]) {
                navigate('/404');
            }
        })
    }

    useEffect(() => {
        fetchData()
    }, [])

    const getExtension = (path: string) => {
        if (!path) return;
        const regex = /(?:\.([^.]+))?$/;

        return regex.exec(path)![1];
    }

    return Object.keys(works).length && id ? (
        <div className="works-item-container">
            <div className="parallax" style={{backgroundImage: `url('${works[id!].main_image_path}')`}}></div>
            <div className="main-info">
                <div className="info-left">
                    <div className="project-name">{works[id].name}</div>
                    <div>{works[id!].subtitle}</div>
                    <div>{works[id!].area}</div>
                </div>
                <div className="info-right">
                    <div>Location: {works[id!].location}</div>
                    <div>Year: {works[id!].year}</div>
                    <div>Status: {works[id!].status}</div>
                </div>
            </div>
            <div className="additional-info">
                <div className="info-left standard-size">
                    <Markdown components={{ a: LinkRenderer }}
                        >{works[id!].description}</Markdown>

                </div>
                <div className="info-right">
                    {works[id!].secondary_image_path.length < 2 ?
                        <img src={`${works[id!].secondary_image_path}`} className="secondary-image"/>
                        :
                        <CarouselContainer>
                            {works[id!].secondary_image_path.map((image: string) => (
                                <img src={image} className="carousel-image"/>
                            ))}
                        </CarouselContainer>
                    }
                </div>
            </div>

            <div className="divider"></div>
            <div className="gallery">
                {
                    works[id!].images.map((item: any) => {
                        if (typeof item === 'string') {
                            let extension = getExtension(item);
                            return (
                                extension && videoExtensions.includes(extension) ?
                                    (<div className="video-container">
                                        <video className="gallery-image" controls autoPlay muted loop>
                                            <source src={item} type={`video/${extension}`}/>
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>)
                                    :
                                    <div className="image-container">
                                        <img src={`${item}`} className="gallery-image"/>
                                    </div>
                            )
                        } else {
                            return (
                                <div className="image-container">
                                    <CarouselContainer>
                                        {item.map((path: string) => (
                                            <img src={path} className="carousel-image"/>
                                        ))}
                                    </CarouselContainer>
                                </div>
                            )
                        }
                    })
                }
            </div>
            <Footer/>
        </div>
    ) : null;
}

function LinkRenderer(props: any) {
    console.log({ props });
    return (
        <a href={props.href} target="_blank" rel="noreferrer">
            {props.children}
        </a>
    );
}