import * as React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './404.scss';

export const NotFound: React.FC = () => {


    return (
        <>
            <div className="error-page">
                <div>
                    <h1 data-h1="404">404</h1>
                    <p data-p="NOT FOUND">NOT FOUND</p>
                </div>
            </div>
        </>
    );
}