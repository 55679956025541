import * as React from 'react';
import {CSSProperties} from 'react';
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './Carousel.scss';


const arrowStyles: CSSProperties = {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 25px)',
    fontSize: '50px',
    width: 50,
    backgroundColor: 'transparent',
    border: 'none',
    transform: 'scaleX(0.75)',
    height: 50,
    cursor: 'pointer',
};
export const CarouselContainer: React.FC<any> = ({children}) => {
    return (
        <Carousel autoPlay={true} swipeable={false} showThumbs={false} showIndicators={false} dynamicHeight={false} infiniteLoop={true} showStatus={false}
                  renderArrowPrev={(onClickHandler, hasPrev, label) =>
                      hasPrev && (
                          <button type="button" onClick={onClickHandler} title={label} style={{...arrowStyles, left: 0}}>
                              &lt;
                          </button>
                      )
                  }
                  renderArrowNext={(onClickHandler, hasNext, label) =>
                      hasNext && (
                          <button type="button" onClick={onClickHandler} title={label} style={{...arrowStyles, right: 0}}>
                              &gt;
                          </button>
                      )
                  }>
            {children}
        </Carousel>
    );
}