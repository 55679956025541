import * as React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './DesignList.scss';
import {CarouselContainer} from "../../components/Carousel/Carousel";
import {useEffect, useState} from "react";
import {Footer} from "../../components/Footer/Footer";

let Scroll = require('react-scroll');
let Element = Scroll.Element;
let scroller = Scroll.scroller;
export const DesignList: React.FC = () => {
    const [designs, setDesigns] = useState<any>([]);

    const fetchData = () => {

        fetch('/data/design-list.json'
            , {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        ).then((response) => {
            return response.json();
        }).then(res => {
            setDesigns(res)
        })
    }

    useEffect(()=>{
        fetchData()
    },[])

    return (
        <div className="designs-container">

            {
                designs.map((design: any, index: number) => (
                    <>
                        <Element className="design-container" name={design.name}>
                            <div className="left-area">
                                <CarouselContainer>
                                    {design.images.map((image: string) => (
                                        <img src={image} className="carousel-image"/>
                                    ))}
                                </CarouselContainer>
                            </div>
                            <div className="right-area">
                                <div className="name">{design.name}</div>
                                <div className="type">{design.type}</div>
                                <div className="subtitle-container">
                                <div className="subtitle">Material: {design.material}</div>
                                <div className="subtitle">Size: {design.size}</div>
                                <div className="subtitle">
                                    Seen in:&nbsp;
                                    <a href={design.seenInPath} className="seen">{design.seenIn}</a>
                                </div>
                                </div>
                                <div className="small-divider"></div>
                                <div className="description">{design.description}</div>
                            </div>
                        </Element>
                        <div className="divider"></div>
                    </>
                ))
            }
            <Footer/>
        </div>
    );
}