import * as React from 'react';
import './Home.scss';
import {Footer} from "../../components/Footer/Footer";

export const Home: React.FC = () => {

    return (
        <>
            <div className="home-container">
                <div className="home-text">
                    Architecture,
                    <br/>
                    Interiors and Design Studio
                    <br/>
                    <br/>
                    Partizanski Odredi 37/21,
                    <br/>
                    Skopje
                    <br/>
                    <br/>
                    <a href="mailto:contact@studiokrug.com">contact@studiokrug.com</a>
                </div>
            </div>
            <Footer/>
        </>
    );
}