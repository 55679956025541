import * as React from 'react';
import './LibraryList.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {CarouselContainer} from "../../components/Carousel/Carousel";
import {useEffect, useRef, useState} from "react";
import {Footer} from "../../components/Footer/Footer";
import Markdown from "react-markdown";

let Scroll = require('react-scroll');
let Element = Scroll.Element;
let scroller = Scroll.scroller;
export const LibraryList: React.FC = () => {
    const [library, setLibrary] = useState<any>([]);
    const textArea = useRef(null);

    const fetchData = () => {

        fetch('/data/library-list.json'
            , {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        ).then((response) => {
            return response.json();
        }).then(res => {
            setLibrary(res)
        })
    }

    useEffect(()=>{
        fetchData()
    },[])

    const convertNumber = (number: number) => {
        let num = number.toString();
        while (num.length < 2) num = "0" + num;
        return num;
    }

    const shouldSetColumns = () => {
        return textArea?.current && textArea?.current['offsetHeight'] - 68.5 > window.innerHeight;
    }

    return (
        <div className="library-container">
            {
                library.map((design: any, index: number) => (
                    <div className="library-scroll" onClick={() => scroller.scrollTo(design.name, {
                        duration: 1000,
                        offset: -68.5,
                        smooth: true
                    })}
                    >
                        {convertNumber(index + 1) + '. ' + design.name}
                    </div>
                ))
            }
            {
                library.map((design: any, index: number) => (
                    <>
                        <div className="divider"></div>
                        <Element className="library-container" name={design.name}>
                            <div className="left-area">
                                <div className="title">{convertNumber(index + 1) + '. ' + design.name}</div>
                                <div className="library-text" ref={textArea}>

                                <Markdown
                                    className={['text-container', shouldSetColumns() ? 'columns' : ''].join(' ')}>{design.additional_text}</Markdown>
                                </div>
                            </div>
                            <div className="right-area">
                                <CarouselContainer>
                                    {design.images.map((image: string) => (
                                        <img src={image} className="carousel-image"/>
                                    ))}
                                </CarouselContainer>
                            </div>
                        </Element>
                    </>
                ))
            }
            <Footer/>
        </div>
    );
}
