import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import './LibraryItem.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Footer} from "../../../components/Footer/Footer";
import {useParams} from "react-router-dom";
import Markdown from "react-markdown";

export const LibraryItem: React.FC = () => {
    const [libraryItem, setLibraryItem] = useState<any>([]);
    const {index} = useParams();
    const textArea = useRef(null);
    const fetchData = () => {

        fetch('/data/library-list.json'
            , {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        ).then((response) => {
            return response.json();
        }).then(res => {
            console.log(res[+index! - 1])
            setLibraryItem(res[+index! - 1])
        })
    }

    const shouldSetColumns = () => {
        return textArea?.current && textArea?.current['offsetHeight'] - 68.5 > window.innerHeight;
    }

    useEffect(() => {
        fetchData()
        console.log('ta', textArea)
    }, [])

    return (
        <div className="library-item-container">
            <div className="title">{libraryItem.name}</div>
            <div ref={textArea}>
                <Markdown
                    className={['text-container', shouldSetColumns() ? 'columns' : ''].join(' ')}>{libraryItem.additional_text}</Markdown>
            </div>
            <Footer/>
        </div>
    );
}
