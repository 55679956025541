import * as React from 'react';
import './AboutUs.scss';

export const AboutUs: React.FC = () => {

    return (
        <div className="about-us-container">
            <div className="main">
                <div className="main-content">
                    ARCHITECTURAL STUDIO <b>KRUG</b>
                    <br/>
                    <br/>
                    Architectural Studio KRUG is an architectural practice founded by architects Konstantin Manu and
                    Marija
                    Mitrovic in 2005. The studio, licensed A in design, supervision and revision, has a very diverse
                    experience in the field of architectural design, ranging from master planning to interior design.
                    <br/><br/>
                    The most substantial project of the Studio to date is the design of the clinical center Mother
                    Teresa in
                    Skopje, developed with the company AREN from Torino. Studio KRUG was responsible for the complete
                    architectural design of the clinical center, coordination of phases, adaptation to the Macedonian
                    architectural standards and laws, as well as complete interior design, including a room book and
                    signalization. The project was completed over a period of over five years, giving architects
                    Konstantin
                    Manu and Marija Mitrovic, the unique experience and expertise in Macedonia in the field of
                    healthcare
                    architecture.
                </div>
            </div>
            <div className="divider"/>
            <div className="activities">
                <div className="left-area">
                    <b>ACTIVITIES</b>
                </div>
                <div className="right-area">
                    <ul>
                        <li>Design and preparation of technical documentation for individual and collective residential
                            buildings
                        </li>
                        <li>Design and preparation of technical documentation for healthcare, business, commercial,
                            social, industrial, cultural, sport, infrastructure and any other kind of buildings.
                            Coordination with all other design phases: structural, electrical and mechanical
                            installation, water and sewage, plans for fire protection and hygiene – Technical
                            Protection, Energy efficiency, Acoustics and noise level.
                        </li>
                        <li>Interior design for all types of buildings, residential or commercial;</li>
                        <li>Professional supervision during the building process, from the beginning to the completion
                            of all-final phases;
                        </li>
                        <li>Professional revision/audit of plans on all phases and types of buildings;</li>
                        <li>Preparation of protocols/statements for issuing the Licenses for usage and technical
                            approval for buildings;
                        </li>
                        <li>Providing documentation for issuing a Building License and corresponding in communications
                            with regional offices of the Ministry of Transport and Communication and Ministry for
                            economy.
                        </li>

                    </ul>
                </div>
            </div>
            <div className="divider"/>
            <div className="team">
                <div className="left-area">
                    Present <b>team</b>
                </div>
                <div className="right-area">
                    <ul className="no-bullets">
                        <li>Marija Mitrovic (founder)</li>
                        <li>Konstantin Manu (founder)</li>
                        <li>Gorjan Venov</li>
                        <li>Kalina Jovanovska</li>
                    </ul>
                </div>
            </div>
            <div className="divider"/>
            <div className="contact">
                <div className="left-area">
                    <b>CONTACT</b>
                </div>
                <div className="right-area">
                    Architectural Studio KRUG, Partizanska No. 37/21 Skopje
                    <br/><br/>
                    <a href="tel:+38923222695">+389 2 3222 695</a>
                    <br/>
                    <a href="tel:+38978303776">+389 78 303 776</a>
                    <br/><br/>
                    E-mail: <a href="mailto:contact@studiokrug.com">contact@studiokrug.com</a>
                    <br/>
                    <a href="https://www.studiokrug.com">www.studiokrug.com</a>
                </div>
            </div>
        </div>
    );
}