import React from 'react';
import './App.scss';
import {Navbar} from "./containers/navbar/Navbar";
import {Home} from "./containers/home/Home";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {WorksList} from "./containers/works/WorksList";
import {WorksItem} from "./containers/works/WorksItem/WorksItem";
import {LibraryList} from "./containers/library/LibraryList";
import {DesignList} from "./containers/design/DesignList";
import {AboutUs} from "./containers/about/AboutUs";
import {NotFound} from "./containers/404/404";
import {Press} from "./containers/press/Press";
import {LibraryItem} from "./containers/library/LibraryItem/LibraryItem";

function App() {
    return (
        <BrowserRouter>
            <Navbar/>
            <Routes>
                <Route
                    path=""
                    element={<Home/>}
                    key="home"
                />
                <Route
                    path="works"
                    element={<WorksList/>}
                    key="works-all"
                />
                <Route
                    path="/works/:id"
                    element={<WorksItem/>}
                    key="work"
                />
                <Route
                    path="design"
                    element={<DesignList/>}
                    key="design"
                />
                <Route
                    path="references"
                    element={<LibraryList/>}
                    key="references"
                />
                <Route
                    path="/references/:index"
                    element={<LibraryItem/>}
                    key="reference"
                />
                <Route
                    path="press"
                    element={<Press/>}
                    key="press"
                />
                <Route
                    path="about"
                    element={<AboutUs/>}
                    key="about"
                />
                <Route path="404" element={<NotFound/>} key="404"/>
                <Route path='*' element={<NotFound/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
