import * as React from 'react';
import './Press.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {CarouselContainer} from "../../components/Carousel/Carousel";
import {useEffect, useState} from "react";
import {Footer} from "../../components/Footer/Footer";
import Markdown from "react-markdown";

let Scroll = require('react-scroll');
let Element = Scroll.Element;
let scroller = Scroll.scroller;
export const Press: React.FC = () => {
    const [pressList, setPressList] = useState<any>([]);

    const fetchData = () => {

        fetch('/data/press-list.json'
            , {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        ).then((response) => {
            return response.json();
        }).then(res => {
            setPressList(res)
        })
    }

    useEffect(()=>{
        fetchData()
    },[])

    const convertNumber = (number: number) => {
        let num = number.toString();
        while (num.length < 2) num = "0" + num;
        return num;
    }

    return (
        <div className="press-container">
            {
                pressList.map((press: any, index: number) => (
                    <div className="library-scroll" onClick={() => scroller.scrollTo(press.name, {
                        duration: 1000,
                        offset: -68.5,
                        smooth: true
                    })}
                    >
                        {convertNumber(index + 1) + '. ' + press.name}
                    </div>
                ))
            }
            {
                pressList.map((press: any, index: number) => (
                    <>
                        <div className="divider"></div>
                        <Element className="press-container" name={press.name}>
                            <div className="left-area">
                                <div>{convertNumber(index + 1) + '. ' + press.name}</div>
                                <Markdown
                                    className={['text-container'].join(' ')}>{press.additional_text}</Markdown>

                            </div>
                            <div className="right-area">
                                <CarouselContainer>
                                    {press.images.map((image: string) => (
                                        <img src={image} className="carousel-image"/>
                                    ))}
                                </CarouselContainer>
                            </div>
                        </Element>
                    </>
                ))
            }
            <Footer/>
        </div>
    );
}