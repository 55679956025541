import * as React from "react";
import './Footer.scss';
import {useEffect, useState} from "react";

export const Footer: React.FC = () => {

    const isHome = () => {
        return window.location.pathname.substring(1) === '';
    }

    return (
        <div className={['footer-wrapper'].join(' ')}>
            <div className="footer-container">
                {
                    isHome() ?
                        <div className="left-area">© 2023 Krug</div>
                        :
                        <div className="left-area"><a href="mailto:contact@studiokrug.com">contact@studiokrug.com</a>
                        </div>
                }
                {isHome() ?
                    <div className="center-area"></div> :
                    <div className="center-area">Partizanska No. 37/21 Skopje</div>
                }
                <div className="right-area">
                    <a className="social-link" href="https://www.instagram.com/architecturalstudiokrug/">Instagram</a>
                    <a className="social-link" href="https://www.facebook.com/profile.php?id=61552148295882">Facebook</a>
                </div>
            </div>
        </div>
    );
}
